@import "../node_modules/js-toastr/toast.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    dl{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
    dt{
        font-weight: bold;
    }
}

@layer components{
    @keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;            
        }
    }

    .fade-in-out {
        animation: fadeInOut 5s ease-in-out 1;
        animation-fill-mode: forwards;
    }
}